import { envIsClient, envIsServer } from '@/helper/env'
import { dynamicActivate, extractLocale, defaultEn } from '@/helper/i18n'
import { getMaintenanceRoutePath } from '@/helper/route'
import { baseCommonStore } from '@/store/common'

let routeHistory: Array<string> = []
let previousRoute = '/'

function onPageTransitionStart(router: string) {
  // 页面导航时，保存当前 URL 到历史
  if (routeHistory.length === 0) {
    routeHistory.push(router)
    previousRoute = router
    return
  }

  // 路由进入两次，取一次的值
  if (routeHistory.length > 0 && routeHistory[routeHistory.length - 1] !== router) {
    routeHistory.push(router)
  }

  // 获取数组倒数第二个路由地址
  previousRoute = routeHistory.length < 2 ? '/' : routeHistory[routeHistory.length - 2]
}

export function onBeforeRoute(pageContext) {
  let { urlOriginal, locale, path } = pageContext
  const { maintenanceMode } = baseCommonStore.getState()
  const { urlWithoutLocale, locale: currentLocale } = extractLocale(urlOriginal)
  if (!path) {
    path = urlWithoutLocale
    locale = currentLocale || defaultEn
  }

  onPageTransitionStart(urlOriginal)

  // if (!isLogin && logRegisterVisible) {
  //   setLogRegisterVisible(false)
  // }

  // 修复服务端 ssr 多语言没同步问题
  dynamicActivate(locale)

  // overwrite to maintenance path on maintenance mode on client
  if (maintenanceMode.isMaintenance && envIsClient) path = getMaintenanceRoutePath()
  return {
    pageContext: {
      urlOriginal: envIsServer ? urlWithoutLocale : path,
      path,
      locale,
      previousRoute,
    },
  }
}
